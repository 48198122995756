import { render, staticRenderFns } from "./LoanDetail.vue?vue&type=template&id=6dbe65aa&scoped=true"
import script from "./LoanDetail.vue?vue&type=script&lang=js"
export * from "./LoanDetail.vue?vue&type=script&lang=js"
import style0 from "./LoanDetail.vue?vue&type=style&index=0&id=6dbe65aa&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dbe65aa",
  null
  
)

export default component.exports