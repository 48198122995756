<template>
  <div class="loan-detail" v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_CONFIRM_LOAN_DETAILS.value, path: $route.path}">
    <v-card flat class="loan-detail-content">
      <v-card-title class="loan-detail-content-title">Please confirm your loan details</v-card-title>
      <v-card-text class="loan-detail-content-details">
        <v-card flat class="loan-detail-content-left">
          <div
            class="detail-item"
            v-for="(item, index) of layouts.paymentSetting"
            :key="index"
          >
            <div class="detail-item-up">
              <img class="detail-item-img" :src="item.icon"/>
              <span class="detail-item-text">{{ item.text }}</span>
            </div>
            <div :style="getStyle(item.isRight)" class="detail-item-value">
              {{ layouts.data.loanDetail[item.fieldKey] }}
            </div>
          </div>
        </v-card>
        <v-card flat class="loan-detail-content-right">
          <div class="detail-amount-content">
            <v-card-title class="detail-amount-content-title">
              Loan #
              <v-spacer></v-spacer>
              <span class="color-secondary">{{ layouts.data.loanDetail.loanNo }}</span>
            </v-card-title>
            <v-card-title class="detail-amount-content-title">Amount</v-card-title>
            <v-card-text class="detail-amount-content-value color-primary">{{
                assist.currency.format(layouts.data.loanDetail.approvedAmount)
              }}</v-card-text>
            <v-card-text class="detail-amount-content-text">
              You may follow the given minimum Payment Schedule or manage your loan payment schedule in your account.
              With {{ layouts.data.portfolioParameter.name }} you have the flexibility of paying your loan in full or pay down at any time without prepayment
              penalties. Remember to arrange the Pay Down or Pay In Full payment 3 business days before the due date.
              If you choose to pay down, you can add $20.00 or more to your scheduled payment to pay the loan faster and
              reduce the number of payments.
            </v-card-text>

            <v-card-actions>
              <a class="mt-4" @click="onClickPreview">Preview Payment Plan</a>
            </v-card-actions>

            <v-card-actions class="button-group">
              <web-btn :width="50" :theme="'normal'" @click="onClickBack"><v-icon>navigate_before</v-icon></web-btn>
              <web-btn width="80%" @click="onClickNext">Next</web-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="layouts.dialog.previewDialog"
      persistent
      width="700"
      class="preview-dialog"
    >
      <v-card>
        <v-card-actions>
          <v-card-title class="f-s-20">Payment Plan</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon color="var(--theme_primary)" text @click="layouts.dialog.previewDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-data-table
            :headers="layouts.settings.headers"
            :items=layouts.data.previewInstallmentList
            :server-items-length="999"
            hide-default-footer
            fixed-header
            height="500">
            <template v-slot:item.transactionDate="{ item }">
              {{ item.transactionDate }}<span v-if="layouts.data.loanDetail.regularAmount !== assist.currency.format(item.amount)" class="red--text">*</span>
                        </template>
            <template v-slot:item.amount="{ item }">
              {{ assist.currency.format(item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text><span class="tips-class">*</span> The installment amount is calculated based on the actual days in
          the billing cycle, which may
          be slightly different from the regular amount.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool, Currency, DataType } from '@/assets/js/util'
import WebBtn from '@/components/base/WebBtn'

const tool = new Tool()
const currency = new Currency()
export default {
  name: 'LoanDetail',
  components: {
    WebBtn
  },
  data: () => ({
    assist: {
      tool: tool,
      currency: currency,
      dataType: DataType
    },
    layouts: {
      paymentSetting: [
        {
          text: 'Disbursement Date',
          fieldKey: 'effectiveDate',
          icon: require('@/assets/images/disbursement/Slice 26.png')
        },
        {
          text: 'Regular Installment Amount',
          fieldKey: 'regularAmount',
          icon: require('@/assets/images/disbursement/Slice 31.png'),
          isRight: true
        },
        {
          text: 'First Due Date',
          fieldKey: 'firstDueDate',
          icon: require('@/assets/images/disbursement/Slice 27.png')
        },
        {
          text: 'First Payment Amount',
          fieldKey: 'firstPaymentAmount',
          icon: require('@/assets/images/disbursement/Slice 30.png'),
          isRight: true
        },
        {
          text: 'Maturity Date',
          fieldKey: 'maturityDate',
          icon: require('@/assets/images/disbursement/Slice 28.png')
        },
        {
          text: 'Finance Charge',
          fieldKey: 'financeCharge',
          icon: require('@/assets/images/disbursement/Slice 32.png'),
          isRight: true
        },
        {
          text: 'Total # of Installments',
          fieldKey: 'debitCount',
          icon: require('@/assets/images/disbursement/Slice 33.png')
        },
        {
          text: 'Total $ of Installments',
          fieldKey: 'totalPayment',
          icon: require('@/assets/images/disbursement/Slice 33.png'),
          isRight: true
        },
        {
          text: 'Payment Frequency',
          fieldKey: 'payrollFrequency',
          icon: require('@/assets/images/disbursement/Slice 29.png')
        },
        {
          text: 'APR',
          fieldKey: 'apr',
          icon: require('@/assets/images/disbursement/Slice 34.png'),
          isRight: true
        }
      ],
      data: {
        portfolioParameter: {},
        loanDetail: {
          effectiveDate: null,
          regularAmount: null,
          firstDueDate: null,
          firstPaymentAmount: null,
          maturityDate: null,
          financeCharge: null,
          debitCount: null,
          totalPayment: null,
          payrollFrequency: null,
          apr: null,
          approvedAmount: null,
          loanNo: null
        },
        previewInstallmentList: []
      },
      settings: {
        headers: [
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'transactionDate',
            class: 'header-style-left',
            cellClass: 'cell'
          },
          {
            text: 'Amount',
            template: '{img}',
            value: 'amount',
            sortable: false,
            class: 'header-style',
            cellClass: 'cell'
          }
        ]
      },
      dialog: {
        previewDialog: false
      }
    }
  }),
  mounted () {
    this.layouts.data.loanDetail.approvedAmount = this.store.getters.getApprovedAmount
    this.layouts.data.loanDetail.loanNo = this.store.getters.getLoan.loanNo
    if (tool.isNotEmpty(this.$store.getters.getPortfolioJson)) {
      this.layouts.data.portfolioParameter = this.$store.getters.getPortfolioJson
    }
    this.getLoanDetail()
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (tool.isNotEmpty(val)) {
          this.layouts.data.portfolioParameter = val
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getLoanDetail () {
      OnlineApi.getLoanDetail(result => {
        this.layouts.data.loanDetail.effectiveDate = result.effectiveDate
        this.layouts.data.loanDetail.regularAmount = currency.format(result.regularAmount)
        this.layouts.data.loanDetail.firstDueDate = result.firstDebitDate
        this.layouts.data.loanDetail.firstPaymentAmount = currency.format(result.firstPaymentAmount)
        this.layouts.data.loanDetail.maturityDate = result.lastDebitDate
        this.layouts.data.loanDetail.financeCharge = currency.format(result.totalInterest)
        this.layouts.data.loanDetail.debitCount = result.debitCount
        this.layouts.data.loanDetail.totalPayment = currency.format(result.totalAmount)
        this.layouts.data.loanDetail.payrollFrequency = tool.getEnumTextByValue(DataType.PayrollFrequencyEnum, result.payrollFrequency)
        this.layouts.data.loanDetail.apr = tool.formatPercent(result.apr)
        this.layouts.data.previewInstallmentList = result.installments
      })
    },
    onClickPreview () {
      this.layouts.dialog.previewDialog = true
    },
    getStyle (isRight) {
      return isRight ? 'text-align: right' : 'text-align: left'
    },
    onClickBack () {
      this.$router.push({
        path: '/disbursement/loan-application'
      })
    },
    onClickNext () {
      this.$router.push({
        path: '/disbursement/signature'
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.loan-detail
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  &-content
    width: 80%
    background-color: transparent!important
    &-title
      font-size: 24px
    &-details
      width: 100%
      display: flex
      background-color: transparent!important
    &-left
      width: 55%
      background-color: #F4F5F9
      border-radius: 20px
      display: grid
      grid-template-columns: repeat(2, 5fr)
    &-right
      width: 40%
      margin-left: 5%
      background-color: transparent!important

.detail-item
  width: 100%
  height: 82px
  padding: 28px 22px

  &-up
    display: flex
    align-items: center

  &-img
    width: 16px
    height: 16px

  &-text
    margin-left: 8px
    height: 16px
    line-height: 16px
    font-size: 14px
    font-weight: 400
    text-align: LEFT
    color: #9CA0AB
  &-value
    font-size: 24px
    color: #000410
    font-weight: 500
    margin-top: 12px

.detail-amount-content
  padding-top: 0px
  &-title
    font-size: 18px
  &-text
    font-size: 14px
    font-weight: 400
    text-align: left
    color: #666870
    line-height: 20px
  &-value
    font-size: 48px
    text-align: right
    font-weight: 700

.color-secondary
  color: var(--theme_secondary)!important

.color-primary
  color: var(--theme_primary)!important

.button-group
  width: 100%
  margin-top: 48px
  //justify-content: right
  padding: 16px
</style>
